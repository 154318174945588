.variantButton {
  background-color: var(--btn-bg-color);
  border: 0px;
  padding: 30px !important;
  color: var(--text-primary-color);
  text-transform: none;
  transition: background 0.3s ease;
  cursor: pointer;
}

.variantButtonOutlined {
  background-color: var(--bg-color);
  border: 0px;
  padding: 30px !important;
  color:  var(--text-secondary-color);
  outline: 2px solid var(--btn-bg-color);
  text-transform: none;
  transition: background 0.3s ease;
  cursor: pointer;
}

