:root {
  --logo-url: url(../img/intraquiz-logo-light.png?v=1);
  --bg-color: #f4f5f7;
  --bg-color-transparent: rgba(255, 255, 253, 0.8);

  --theme-color: #fffffc;

  --bg-hover: rgba(0, 0, 0, 0.05);

  --surface-bg-color: #fffffc;

  --divider-color: #d9dce4;

  --btn-bg-color: #7701ff;
  --btn-bg-hover-color: #5f00cd;
  --disabled-bg-color: #d9dce1;

  --text-primary-color: #fffffc;
  --text-secondary-color: #101828;
  --text-tertiary-color: #a5acbe;

  --alert-color: #ffbe08;
  --error-color: #ff0c0c;
  --success-color: #57d9b7;

  --accent-color: #7701ff;
}

body {
  background-color: var(--bg-color);
  color: var(--text-tertiary-color);
}

footer {
  /* background-color: var(--bg-color) !important; */
}

tr {
  border-bottom: 1px solid var(--divider-color);
}

li.active {
  background-color: var(--accent-color) !important;
  color: var(--text-primary-color) !important;
}

textarea.materialize-textarea {
  line-height: 24px;
}

.file-field .btn,
.file-field .btn-large,
.file-field .btn-small {
  float: none;
  height: 36px;
  line-height: 36px;
}

.pagination-page {
  padding-left: 12px;
  padding-right: 12px;
  height: 100%;
  display: flex;
  justify-content: center;
  align-content: center;
  flex-direction: column;
  cursor: pointer;
}

.pagination-page-prev {
  padding-left: 0px;
  padding-right: 8px;
  height: 100%;
  display: flex;
  justify-content: center;
  align-content: center;
  flex-direction: column;
  cursor: pointer;
}

.pagination-page-next {
  padding-left: 8px;
  padding-right: 0px;
  height: 100%;
  display: flex;
  justify-content: center;
  align-content: center;
  flex-direction: column;
  cursor: pointer;
}

@media only screen and (max-width: 992px) {
  .unitable {
    font-size: 12px !important;
    line-height: 16px !important;
  }
}

.th-amount-min {
  width: 100px;
}

.footer-copyright {
  color: var(--text-tertiary-color) !important;
  background-color: rgba(0, 0, 0, 0) !important;
}

.divider {
  background-color: var(--divider-color);
}

.hljs {
  background-color: var(--surface-bg-color);
  border-radius: 15px;
  padding: 15px !important;
}

nav ul a:hover {
  background-color: var(--bg-hover);
}

.sidenav li > a:hover {
  background-color: var(--bg-hover) !important;
}

.material-icons {
  color: var(--text-tertiary-color) !important;
}

.material-icons-outlined {
  color: var(--text-tertiary-color) !important;
}

.sidenav {
  background-color: var(--bg-color);
}

.sidenav li .material-icons {
  color: var(--accent-color) !important;
}

.sidenav li .material-icons-outlined {
  color: var(--accent-color) !important;
}

.sidenav li a {
  color: var(--text-secondary-color) !important;
}

.user-view {
  color: var(--text-secondary-color);
}

.spinner-layer {
  border-color: var(--accent-color);
}

.m-change-theme-btn {
  margin-top: 3px;
  margin-right: 15px;
  cursor: pointer;
}

.modal-content {
  background-color: var(--bg-color);
}

.modal-footer {
  background-color: var(--bg-color) !important;
}

.modal.modal-fixed-footer .modal-footer {
  border-top: 1px solid var(--divider-color);
}

@font-face {
  font-family: "Museo Sans";
  font-style: normal;
  font-weight: 100;
  font-display: swap;
  src: local("Museo Sans"),
    url("../fonts/MuseoSans_100_normal.woff") format("woff"),
    url("../fonts/MuseoSansCyrl_100.otf") format("opentype");
}

@font-face {
  font-family: "Museo Sans";
  font-style: normal;
  font-weight: 300;
  font-display: swap;
  src: local("Museo Sans"),
    url("../fonts/MuseoSans_300_normal.woff") format("woff"),
    url("../fonts/MuseoSansCyrl_300.otf") format("opentype");
}

@font-face {
  font-family: "Museo_Sans_300";
  font-style: normal;
  font-weight: 300;
  font-display: swap;
  src: local("Museo Sans"),
    url("../fonts/MuseoSans_300_normal.woff") format("woff"),
    url("../fonts/MuseoSansCyrl_300.otf") format("opentype");
}

@font-face {
  font-family: "Museo Sans";
  font-style: normal;
  font-weight: 500;
  font-display: swap;
  src: local("Museo Sans"),
    url("../fonts/MuseoSans_500_normal.woff") format("woff"),
    url("../fonts/MuseoSansCyrl_500.otf") format("opentype");
}

@font-face {
  font-family: "Museo Sans";
  font-style: normal;
  font-weight: 700;
  font-display: swap;
  src: local("Museo Sans"),
    url("../fonts/MuseoSans_700_normal.woff") format("woff"),
    url("../fonts/MuseoSansCyrl_700.otf") format("opentype");
}

@font-face {
  font-family: "Museo Sans";
  font-style: normal;
  font-weight: 900;
  font-display: swap;
  src: local("Museo Sans"),
    url("../fonts/MuseoSans_900_normal.woff") format("woff"),
    url("../fonts/MuseoSansCyrl_900.otf") format("opentype");
}

.dropdown-content,
.select-dropdown {
  font-family: "Museo Sans" !important;
  font-weight: 300;
}

input {
  font-family: "Museo Sans" !important;
  font-weight: 300;
  color: var(--text-secondary-color);
}

button {
  font-family: "Museo Sans" !important;
  font-weight: 300;
}

.title {
  font-weight: 900;
  font-size: 36px;
  line-height: 44px;
}

html {
  font-size: 16px;
  line-height: 24px;
  font-weight: 300;
}

h1 {
  font-weight: 900;
  font-size: 32px;
  line-height: 36px;
  margin-top: 20px;
  margin-bottom: 20px;
  color: var(--text-secondary-color);
}

h2 {
  font-weight: 900;
  font-size: 28px;
  line-height: 32px;
  margin-top: 0px;
  color: var(--text-secondary-color);
}

h3 {
  font-weight: 900;
  font-size: 24px;
  line-height: 28px;
  color: var(--text-secondary-color);
}

h4 {
  font-weight: 700;
  font-size: 20px;
  line-height: 24px;
  color: var(--text-secondary-color);
}

h5 {
  font-weight: 700;
  font-size: 16px;
  line-height: 20px;
  color: var(--text-secondary-color);
}

h6 {
  font-weight: 300;
  font-size: 12px;
  color: var(--text-secondary-color);
}

.page-title {
  margin-top: 22px;
}

.card {
  background-color: var(--surface-bg-color);
  box-shadow: var(--light-shadow);
  border-radius: 15px;
  margin-top: 0px;
  margin-bottom: 0px;
}

.s-card-title {
  margin-top: 0px;
  margin-bottom: 0px;
}

.xxxl500 {
  font-size: 32px;
  font-weight: 500;
  line-height: 36px;
  color: var(--text-secondary-color);
}

.xxl500 {
  font-size: 28px;
  font-weight: 500;
  line-height: 36px;
  color: var(--text-secondary-color);
}

.xl700 {
  font-size: 24px;
  font-weight: 700;
  line-height: 32px;
}

.xl500 {
  font-size: 24px;
  font-weight: 500;
  line-height: 32px;
  color: var(--text-secondary-color);
}

.l500c {
  font-size: 20px;
  font-weight: 500;
  line-height: 28px;
  color: var(--text-secondary-color);
}

.l700 {
  font-size: 20px;
  font-weight: 700;
  line-height: 32px;
}

.l500 {
  font-size: 20px;
  font-weight: 500;
  line-height: 32px;
  color: var(--text-secondary-color);
}

.l300 {
  font-size: 20px;
  font-weight: 300;
  line-height: 32px;
}

.m700 {
  font-size: 16px;
  line-height: 24px;
  font-weight: 700;
}

.m500 {
  font-size: 16px;
  line-height: 24px;
  font-weight: 500;
  color: var(--text-secondary-color);
}

.m300 {
  font-size: 16px;
  line-height: 24px;
  font-weight: 300;
}

.s500 {
  font-size: 14px;
  font-weight: 500;
  line-height: 20px;
  color: var(--text-secondary-color);
}

.s300 {
  font-size: 14px;
  font-weight: 300;
  line-height: 20px;
}

.xs300 {
  font-size: 12px;
  font-weight: 300;
  line-height: 16px;
}

.main-card-header {
  font-weight: 900;
  font-size: 22px;
  line-height: 26px;
}

.main-flow-date {
  font-weight: 700;
  font-size: 22px;
  line-height: 26px;
}

.main-card-amount {
  font-weight: 300;
  font-size: 18px;
  line-height: 28px;
}

.progress {
  margin-top: 5px;
  margin-bottom: 12px;
}

.navigation-bar {
  margin-bottom: 0px;
}

.nm {
  margin-bottom: 0px;
  margin-top: 0px;
}

.mb5 {
  margin-bottom: 5px;
}

.mb10 {
  margin-bottom: 10px;
}

.mb15 {
  margin-bottom: 15px;
}

.mb20 {
  margin-bottom: 20px;
}

.mb25 {
  margin-bottom: 25px;
}

.mb30 {
  margin-bottom: 30px;
}

.mb35 {
  margin-bottom: 35px;
}

.mb40 {
  margin-bottom: 40px;
}

.mt10 {
  margin-top: 10px;
}

.mt15 {
  margin-top: 15px;
}

.mt20 {
  margin-top: 20px;
}

.mt25 {
  margin-top: 25px;
}

.mt30 {
  margin-top: 30px;
}

.mt35 {
  margin-top: 35px;
}

.mt40 {
  margin-top: 40px;
}

.green-button {
  background-color: #68c305;
}

.green-button:hover {
  background-color: #68c305;
}

.red-button {
  background-color: #fb8202;
  color: #ffffff !important;
}

input[type="submit"] {
  color: #ffffff;
}

.blue-button {
  background-color: #2c75ff;
  color: white;
  margin-left: 10px;
  text-transform: none;
}

.blue-button:hover {
  background-color: #2c75ff;
}

.white-button {
  background-color: #fff;
  color: #2c75ff;
  margin-left: 10px;
  text-transform: none;
}

.white-button:hover {
  background-color: #fff;
}

.red-button:focus {
  background-color: #fb8202;
}

.grey-button {
  color: black;
  background-color: #f5f5f5;
}

.grey-button:focus {
  background-color: #f5f5f5;
}

.red-button:hover {
  background-color: #fb8202;
}

.grey-button:hover {
  background-color: #fb8202;
  color: white;
}

.sypher-blue {
  color: #2c75ff;
}

.iq-color {
  color: #311b92;
}

.iq-logo {
  width: 80px;
  height: 30px;
  margin-top: 17px;
  background-image: var(--logo-url);
  background-repeat: no-repeat;
  background-size: contain;
}

.iq-logo-m {
  width: 80px;
  height: 30px;
  margin-top: 12.5px;
  background-image: var(--logo-url);
  background-repeat: no-repeat;
  background-size: contain;
  background-position: center;
}

.alert {
  color: var(--alert-color) !important;
}

.error {
  color: var(--error-color) !important;
}

.alert-message {
  color: var(--text-secondary-color);
  background-color: rgba(225, 255, 255, 0);
  border: 2px var(--alert-color) solid;
  margin-top: 25px;
}

.alert-message .card-content {
  padding: 17px;
}

.iq-btn {
  text-transform: none;
  background-color: var(--btn-bg-color);
  color: var(--text-primary-color);
  cursor: pointer;
}

.iq-btn:hover {
  background-color: var(--btn-bg-hover-color);
}

.iq-btn .material-icons-outlined {
  color: var(--text-primary-color) !important;
}

.iq-btn .material-icons {
  color: var(--text-primary-color) !important;
}

.iq-btn.disabled {
  background-color: var(--disabled-bg-color) !important;
}

.iq-btn.disabled .material-icons-outlined {
  color: var(--text-tertiary-color) !important;
}

.iq-btn.disabled .material-icons {
  color: var(--text-tertiary-color) !important;
}

.iq-btn-delete {
  text-transform: none;
  background-color: var(--surface-bg-color);
  color: var(--error-color);
  cursor: pointer;
  font-weight: 500;
}

.iq-btn-delete .material-icons-outlined {
  color: var(--error-color) !important;
}

.iq-btn-delete .material-icons {
  color: var(--error-color) !important;
}

.iq-btn-delete:hover {
  background-color: var(--divider-color);
  color: var(--error-color);
}

.iq-btn-delete.disabled .material-icons-outlined {
  background-color: var(--surface-bg-color) !important;
  color: var(--text-tertiary-color) !important;
}

.iq-btn-delete.disabled .material-icons {
  background-color: var(--surface-bg-color) !important;
  color: var(--text-tertiary-color) !important;
}

.iq-btn-delete.disabled {
  color: var(--text-tertiary-color) !important;
}

i.left {
  margin-right: 8px;
}

.iq-link {
  text-decoration: underline;
  color: var(--accent-color);
  cursor: pointer;
}

.iq-tertiary-link {
  text-decoration: underline;
  color: var(--text-tertiary-color);
  cursor: pointer;
}

a.disabled {
  pointer-events: none;
}

.accent-field {
  background-color: var(--btn-bg-color);
  color: var(--text-primary-color);
  border-radius: 100px;
  padding: 10px !important;
}

.accent-color {
  color: var(--accent-color) !important;
}

.secondary-color {
  color: var(--text-secondary-color) !important;
}

.tertiary-color {
  color: var(--text-tertiary-color) !important;
}

.success-color {
  color: var(--success-color) !important;
}

.error-color {
  color: var(--error-color) !important;
}

.iq-color-bg {
  background-color: #311b92 !important;
}

.page-footer {
  padding-top: 0px;
}

.first-element {
  margin-top: 25px;
}

.datepicker-date-display,
.timepicker-digital-display {
  background-color: #fb8202;
}

.timepicker-canvas line {
  stroke: #f97b7b !important;
}

.timepicker-canvas-bearing {
  fill: #f97b7b !important;
}

.timepicker-canvas-bg {
  fill: #f97b7b !important;
}

.timepicker-tick:hover {
  background: #f97b7b !important;
}

.datepicker-cancel,
.datepicker-clear,
.datepicker-today,
.datepicker-done,
.timepicker-close {
  color: #fb8202;
}

.datepicker-table td.is-today {
  color: #fb8202;
}

.datepicker-table td.is-selected {
  background-color: #fb8202;
  color: #fff;
}

.sypher-red-bg {
  background-color: #fb8202;
}

.sypher-green {
  color: #069e2d;
}

#toast-container {
  top: auto !important;
  right: auto !important;
  bottom: 0%;
  left: 0%;
  min-width: 100%;
}

.toast {
  font-weight: 400;
  border-radius: 0;
  height: 5em;
}

.section-header {
  margin-bottom: 20px;
  color: #fb8202;
}

.tabs .indicator {
  background-color: #fb8202;
}

.tabs .tab a {
  color: #fb8202;
  letter-spacing: 1px;
}

.tabs .tab a.active {
  color: #fb8202;
}

[type="checkbox"].filled-in:checked + span:not(.lever):after {
  border: 2px solid var(--accent-color);
  background-color: var(--accent-color);
}

/*
.checkbox-indigo[type="checkbox"] + label:before{
    border: 2px solid indigo;
    background: transparent;
    color: red;
  }
  .checkbox-indigo[type="checkbox"]:checked + label:before{
    border: 2px solid transparent;
    border-bottom: 2px solid indigo;
    border-right: 2px solid indigo;
    background: transparent;
    color: red;
  }
  .checkbox-indigo.filled-in[type="checkbox"] + label:after{
    border: 2px solid indigo;
    background: transparent;
    color: red;
  }
  .checkbox-indigo.filled-in[type="checkbox"]:checked + label:after{
    background: indigo;
    color: red;
  }
  .checkbox-indigo.filled-in[type="checkbox"]:checked + label:before{
    border-top: 2px solid transparent;
    border-left: 2px solid transparent;
    border-right: 2px solid #fff;
    border-bottom: 2px solid #fff;
    color: red;
  }*/

ul.dropdown-content.select-dropdown li span {
  color: var(--accent-color);
}

[type="checkbox"]:checked + span:not(.lever):before {
  border-right: 2px solid var(--success-color);
  border-bottom: 2px solid var(--success-color);
}

[type="checkbox"]:checked:disabled + span:before {
  border-right: 2px solid var(--text-tertiary-color);
  border-bottom: 2px solid var(--text-tertiary-color);
}

[type="checkbox"] + span:not(.lever):before,
[type="checkbox"]:not(.filled-in) + span:not(.lever):after {
  border: 2px solid var(--text-tertiary-color);
}

[type="checkbox"]:not(:checked):disabled + span:not(.lever):before {
  border: none;
  background-color: var(--text-tertiary-color);
}

[type="radio"]:checked + span:after,
[type="radio"].with-gap:checked + span:after {
  background-color: #fb8202;
}

[type="radio"]:checked + span:after,
[type="radio"].with-gap:checked + span:before,
[type="radio"].with-gap:checked + span:after {
  border: 2px solid #fb8202;
}

.fin-indicator {
  border-bottom: 1.5px dashed;
}

.fin-indicator:hover {
  cursor: pointer;
}

/* label focus color */
.input-field input:focus + label {
  color: var(--accent-color) !important;
}
.input-field label {
  color: var(--text-tertiary-color) !important;
}
/* label underline focus color */
.row .input-field input:focus {
  border-bottom: 1px solid var(--accent-color) !important;
  box-shadow: 0 1px 0 0 var(--accent-color) !important;
}
.row .input-field input {
  border-bottom: 1px solid var(--text-tertiary-color) !important;
}

/* label focus color */
.input-field textarea:focus + label {
  color: var(--accent-color) !important;
}
/* label underline focus color */
.row .input-field textarea:focus {
  border-bottom: 1px solid var(--accent-color) !important;
  box-shadow: 0 1px 0 0 var(--accent-color) !important;
}

.row .input-field textarea {
  border-bottom: 1px solid var(--text-tertiary-color) !important;
}

textarea {
  color: var(--text-secondary-color);
}

::-webkit-input-placeholder {
  /* WebKit, Blink, Edge */
  color: var(--text-tertiary-color);
}
:-moz-placeholder {
  /* Mozilla Firefox 4 to 18 */
  color: var(--text-tertiary-color);
  opacity: 1;
}
::-moz-placeholder {
  /* Mozilla Firefox 19+ */
  color: var(--text-tertiary-color);
  opacity: 1;
}
:-ms-input-placeholder {
  /* Internet Explorer 10-11 */
  color: var(--text-tertiary-color);
}
::-ms-input-placeholder {
  /* Microsoft Edge */
  color: var(--text-tertiary-color);
}

::placeholder {
  /* Most modern browsers support this now. */
  color: var(--text-tertiary-color);
}

/* .dropdown-content li > a {
  color: var(--accent-color) !important;
} */

.container {
  margin: 0 auto;
  max-width: 1280px;
  width: 97.5%;
}

@media only screen and (min-width: 601px) {
  .container {
    width: 85%;
  }
}
@media only screen and (min-width: 993px) {
  .container {
    width: 87%; /* 82% */
  }

  .regular-container {
    width: 82%;
  }

  .container-navbar {
    width: 97% !important;
    max-width: 97%;
  }
}

.reg-input {
  font-size: 11pt !important;
}

body {
  display: flex;
  min-height: 100vh;
  flex-direction: column;
  font-family: "Museo Sans", -apple-system, system-ui, Roboto,
    BlinkMacSystemFont, "Segoe UI", "Helvetica Neue", Arial, sans-serif;
}

main {
  flex: 1 0 auto;
}

.m-item {
  margin-left: -5px !important;
}

.operation-card {
  cursor: pointer;
}

.autocomplete-content li .highlight {
  color: #2c75ff !important;
}

.dropdown-content li > a,
.dropdown-content li > span {
  color: var(--accent-color) !important;
  background-color: var(--surface-bg-color) !important;
}

.dropdown-content li > a,
.dropdown-content li > span:hover {
  background-color: var(--divider-color) !important;
}

svg > path:nth-of-type(1) {
  fill: var(--text-secondary-color) !important;
}

/* .dropdown-content-blur {
  background-color: rgba(255, 255, 255, 0.8) !important;
  -webkit-backdrop-filter: blur(7px);
  backdrop-filter: blur(7px);
} */

.tutorial-m {
  height: 0;
  position: relative;
  padding-bottom: 130%;
}

.tutorial-m iframe {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
}

.tutorial-d {
  height: 0;
  position: relative;
  padding-bottom: 100%;
}

.tutorial-d iframe {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
}

.landing-text {
  line-height: 1.6;
  font-size: 130%;
  opacity: 0.6;
  margin-top: 0px;
}

.p-row {
  margin-bottom: -20px !important;
  margin-top: -20px !important;
}

#d-side-bar {
  width: 5.5%;
  top: 64px;
  left: 0px;
}

#d-side-bar li:hover {
  background-color: rgba(0, 0, 0, 0.1);
}

#dropdown-company-menu li:hover {
  background-color: rgba(0, 0, 0, 0.1);
}

/* #side-bar li > a :hover {
  background-color:  rgba(0,0,0,0.5) !important;
} */

.side-bar-no-btn {
  background-color: rgba(0, 0, 0, 0) !important;
}

@media (min-width: 991px) {
  /* main {
    padding-left: 91px;
  } */
  .sidenav li {
    padding-top: 5px;
    padding-bottom: 5px;
  }

  .d-flow-card {
    margin-bottom: 23px;
  }

  .d-no-shadow {
    box-shadow: none !important;
  }

  #d-side-bar {
    background-color: rgba(255, 255, 255, 0.8);
    -webkit-backdrop-filter: blur(7px);
    backdrop-filter: blur(7px);
  }
}

nav {
  background-color: var(--bg-color-transparent) !important;
  -webkit-backdrop-filter: blur(7px);
  backdrop-filter: blur(7px);
  line-height: 64px;
}

.nav-wrapper a {
  color: var(--text-secondary-color);
}

.planned {
  background-color: rgba(255, 255, 255, 0.6) !important;
  -webkit-backdrop-filter: blur(7px);
  backdrop-filter: blur(7px);
}

.no-background {
  background-image: none !important;
}

@media only screen and (max-width: 992px) {
  header,
  main,
  footer {
    padding-left: 0;
  }

  /* .funds-content { 
    width: 92vw; 
  } */
  .sidenav li > a:hover {
    background-color: rgba(0, 0, 0, 0.1);
  }

  .mobile-bg {
    background-color: #f5f5f5 !important;
  }

  .modal {
    font-size: 14px;
    line-height: 20px;
  }

  .full-modal {
    width: 100% !important;
    height: 100% !important;
    max-height: 100% !important;
    top: 0 !important;
  }

  /* .sidenav-overlay {
    opacity: 0 !important;
  } */

  /* #side-bar {
    background-color: rgba(255,255,255,0.8);
    -webkit-backdrop-filter: blur(7px);
    backdrop-filter: blur(7px);
  } */
}

.collapsible .collapsible-body-without-padding {
  padding: 8px 0px 0px 0px !important;
  border-width: 0px;
}

.no-border {
  border-width: 0px !important;
  border: 0 !important;
  box-shadow: none !important;
}

#funds-info-place .collapsible .collapsible-header {
  background-color: rgba(255, 255, 255, 0);
  padding: 0px !important;
  margin-bottom: 0px !important;
}

.collapsible .collapsible-header .no-margin-padding {
  margin-bottom: none !important;
}

.syfer-link {
  text-decoration: underline;
  color: #fb8202;
  cursor: pointer;
}

.g-recaptcha {
  display: table;
  margin: 0 auto;
}

.check-list {
  margin-top: 15px !important;
}

.calendar-cell {
  /* padding-top: 5px !important;
  padding-bottom: 5px !important;
  padding-left: 5px !important;
  padding-right: 5px !important;
  margin-left: 5vw !important; */
  width: 14.28% !important;
  padding: 7px !important;
}

.bold {
  font-weight: 800;
}

.fact-day {
  background-color: rgba(44, 117, 255, 1);
  color: #fff;
  font-weight: 400;
  cursor: pointer;
}

.plan-day {
  background-color: rgba(153, 102, 255, 1);
  color: #fff;
  font-weight: 400;
  cursor: pointer;
}

.today {
  outline: 2px solid #fb8202;
  outline-offset: -2px;
}

.small-padding {
  padding: 10px 14px 14px 14px !important;
}

.wp {
  padding: 0px !important;
}

.rp {
  padding-left: 20px;
}

.top-rp {
  padding-top: 12px;
}

.d-flex {
  display: flex;
}

.switch label input[type="checkbox"]:checked + .lever {
  background-color: var(--accent-color);
}
.switch label input[type="checkbox"]:checked + .lever:after {
  background-color: var(--text-primary-color);
}

.switch label input[type="checkbox"]:checked + .lever:before,
.switch label input[type="checkbox"]:checked + .lever:after {
  left: 24px;
}

.switch label .lever:before {
  background-color: var(--accent-color);
  opacity: 0.2;
}

.switch label .lever:before,
.switch label .lever:after {
  top: 4px;
  left: 5px;
}

.switch label .lever {
  background-color: var(--text-tertiary-color);
  height: 28px;
  width: 48px;
  border-radius: 25px;
}

.switch label .lever:after {
  background-color: var(--text-primary-color);
}

.iq-watermark {
  padding: 10px 20px;
  border-radius: 50px;
  background-color: var(--bg-color-transparent) !important;
}